import axios from '../utils/axiosSetup';

export const fetchLoanApplicationsForAccount = async () => {
  const response = await axios.get(`loan-applications`);
  return response.data;
};

export const fetchLoanApplicationById = async (id) => {
  const response = await axios.get(`loan-applications/${id}`);
  return response.data;
};

export const fetchLoanApplicationDecision = async (loanApplicationId) => {
  await axios.get(`loan-applications/${loanApplicationId}/decision`);
  const applicationResponse = await axios.get(`/loan-applications/${loanApplicationId}`);
  return applicationResponse.data;
};

export const fetchLoanDetailsByTuition = async (loanApplicationId, tuitionRequest) => {
  const response = await axios.get(`/loan-applications/${loanApplicationId}/monthly-payment?tuition=${tuitionRequest}`);
  return response.data;
}

export const updateLoanApplication = async (loanApplicationId, data) => {
  const applicationResponse = await axios.patch(`loan-applications/${loanApplicationId}`, data);
  return applicationResponse.data;
};

export const createStripeCheckoutSession = async (loanApplicationId) => {
  const response = await axios.post(`/loan-applications/${loanApplicationId}/checkout-session`);
  return response.data;
};

export const refreshStripeCheckoutSessionStatus = async (loanApplicationId) => {
  const response = await axios.patch(`/loan-applications/${loanApplicationId}/checkout-session/refresh-status`);
  return response.data;
};

export const fetchOrCreateLoanPacket = async (loanApplicationId) => {
  const response = await axios.get(`/loan-applications/${loanApplicationId}/loan-packet`);
  return response.data;
};

export const getStatusOfExistingLoanPacket = async (loanApplicationId) => {
  const response = await axios.get(`/loan-applications/${loanApplicationId}/loan-packet/status`);
  return response.data;
};

export const fetchOrCreateAutopayConsent = async (loanApplicationId) => {
  const response = await axios.get(`/loan-applications/${loanApplicationId}/autopay-consent`);
  return response.data;
};

export const getStatusOfExistingAutopayConsent = async (loanApplicationId) => {
  const response = await axios.get(`/loan-applications/${loanApplicationId}/autopay-consent/status`);
  return response.data;
};

export const declineAutopayPrompt = async (loanApplicationId) => {
  await axios.patch(`loan-applications/${loanApplicationId}/autopay-consent/decline`);
  return;
};
