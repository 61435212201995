import React from 'react';
import FortifyIcon from '../FortifyIcon';
import { Button } from '@mui/material';

const Completed = ({applicationData}) => {
  const firstPaymentDueDate = new Date(applicationData.loan.firstPaymentDueDate);

  const formattedFirstPaymentDate = firstPaymentDueDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  });

  const dayNumber = firstPaymentDueDate.getUTCDate();
  const ordinalSuffix = ['th', 'st', 'nd', 'rd'][(dayNumber % 10 > 3) ? 0 : (dayNumber % 100 - dayNumber % 10 !== 10) * dayNumber % 10];

  const formattedDateWithOrdinal = formattedFirstPaymentDate.replace(/\d+/, `${dayNumber}${ordinalSuffix}`);

  const monthlyPayment = parseFloat(applicationData.loan.monthlyPayment);
  const formattedMonthlyPayment = monthlyPayment.toFixed(2);
  const email = applicationData.student.account.email;

  const loanCounselingLink = "https://cal.com/bob-fortifyeducation.com/loan-counseling"

  return (
    <>
      <FortifyIcon />

      <div className='w-4/5 text-center'>
        <h1 className="pb-4 text-2xl font-lora"><b>You're all done!</b></h1>
      </div>

      <div className="text-center space-y-6" style={{maxWidth: '350px'}}>
        <div className="space-y-4 text-center">
          <p>Your first payment of <b>${formattedMonthlyPayment}</b> will be on <b>{formattedDateWithOrdinal}</b>.</p>
          <p>You'll get an onboarding email to {email} from <a href="https://www.zuntafi.com" target='_blank'><b><u>ZuntaFi</u></b></a>, which is where you'll make your first payment.</p>
        </div>
        <hr/>
        <div>
          <p>The last step is to meet your Fortify counselor. We provide you with a <b>free counselor</b> to help you build your credit, plan for your financial future, and even to help you land a job once you graduate.</p>
          <Button variant="contained" color="primary" style={{ marginTop: '25px', marginBottom: '25px', minWidth: '150px', height: '40px', fontSize: '15px' }} onClick={() => window.location.href = loanCounselingLink}>Schedule</Button>
          <br/>
          <p>Congratulations again! We're very excited to be supporting your journey through school.</p>
        </div>
      </div>
    </>
  )
};

export default Completed;
