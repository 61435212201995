import React, { useState } from 'react';
import { Button, Box, Alert, CircularProgress } from '@mui/material';
import { declineAutopayPrompt, fetchOrCreateAutopayConsent } from '../../api/application';
import FortifyIcon from '../FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const AutopayPrompt = ({ applicationData, updateStage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleDecline = async () => {
    setIsLoading(true);
    setError('');
    try {
      await declineAutopayPrompt(applicationData.id);
      updateStage('completed');
    } catch (error) {
      logErrorToConsole("Error declining autopay", error);
      logErrorToSentryWithContext(error, {
        applicationId: applicationData.id,
      });

      setError('An error occurred while processing your request - please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAgree = async () => {
    setIsLoading(true);
    setError('');
    try {
      const autopayResponse = await fetchOrCreateAutopayConsent(applicationData.id);

      if (autopayResponse.status === 'signed') {
        updateStage(autopayResponse.next_step ?? "completed"); // should always redirect to completed anyway
      } else if (autopayResponse.signature_link) {
        window.open(autopayResponse.signature_link, '_self');
      }
    } catch (error) {
      logErrorToConsole("Error setting up autopay", error);
      logErrorToSentryWithContext(error, {
        applicationId: applicationData.id,
      });

      setError('An error occurred while creating your autopay document. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <>
      <FortifyIcon />

      <Box className="w-full md:w-4/5 lg:w-3/4 text-center">
        <h1 className='pb-4 text-2xl font-lora'><b>You're missing savings!</b></h1>

        <div className="flex items-center p-6 mt-4 mb-8 rounded-xl space-x-4" style={{ backgroundColor: '#FFF3D0' }}>
          <img src="/warning.svg" alt="Warning" className="w-12 h-12" />
          <p className="text-sm sm:text-base md:text-lg text-left">
            Set up autopay to get a <strong>0.25% interest rate discount</strong>, reducing your total payments.
          </p>
        </div>

        <p className="text-left mb-6 underline">
          Here's why we strongly recommend you set up autopay:
        </p>

        <ul className="text-left list-disc pl-6 mb-6">
          <li>A <b>0.25% interest rate discount</b> on payments made via autopay.*</li>
          <li>Avoid missing payments, which could damage your credit score.</li>
          <li>Simplify your bills every month with automatic monthly payments.</li>
        </ul>

        <p className="text-left">
          By enabling autopay, you're taking a smart step towards financial responsibility and savings.
        </p>

        {error && (
          <Alert severity="error" className="mb-4">
            {error}
          </Alert>
        )}

        <Box className="flex justify-center space-x-4 mt-6">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <div className="w-full flex justify-between space-x-2 md:space-x-4 h-[60px] sm:h-[40px]">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleDecline}
                disabled={isLoading}
                className="flex-1"
              >
                Decline
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleAgree}
                disabled={isLoading}
                className="flex-1"
              >
                Enable autopay
              </Button>
            </div>
          )}
        </Box>

        <div className='mt-2 sm:mt-4 text-left text-legal-gray' style={{ fontSize: '0.75rem' }}>
          <p>Terms and conditions apply. Your 0.25% interest rate discount benefit will be applied as an equivalent discount for payments received via autopay and will be reflected in the payment application on your monthly billing statement and online payment history.</p>
        </div>
      </Box>
    </>
  );
};

export default AutopayPrompt;