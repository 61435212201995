class LoanApplicationData {
    constructor(data) {
      this.id = data.id;
      this.schoolId = data.school_id;
      this.programId = data.program_id;
      this.studentId = data.student_id;
      this.archivedAt = data.archived_at;
      this.archiveReason = data.archive_reason;
      this.underwritingDecision = data.underwriting_decision;
      this.underwritingDecisionMadeAt = data.underwriting_decision_made_at;
      this.schoolCertification = data.school_certification;
      this.schoolCertificationMadeAt = data.school_certification_made_at;
      this.submittedAt = data.submitted_at;

      this.startDate = data.start_date;

      this.tuitionRequest = data.tuition_request;
      this.tuitionRequestStatus = data.tuition_request_status;

      this.nextStep = data.next_step;

      this.student = this.mapStudent(data.student);
      this.school = this.mapSchool(data.school);
      this.program = this.mapProgram(data.program);
      this.loan = this.mapLoan(data.loan);
    }
  
    mapStudent(studentData) {
      if (!studentData) return null;
      return {
        id: studentData.id,
        account: studentData.account ? this.mapAccount(studentData.account) : null,
        markedIneligibleAt: studentData.marked_ineligible_at,
        ineligibleReasons: studentData.ineligible_reasons,
        stripeIdentityStatus: studentData.stripe_identity_session_status,
        nameVerifiedAgainstStripe: studentData.name_verified_against_stripe,
        dobVerifiedAgainstStripe: studentData.dob_verified_against_stripe,
        ssnVerifiedAgainstStripe: studentData.ssn_verified_against_stripe,
        ofacStatus: studentData.ofac_status,
        plaidItemStatus: studentData.plaid_item_status,
      };
    }

    mapSchool(schoolData) {
      if (!schoolData) return null;
      return {
        id: schoolData.id,
        school_name: schoolData.school_name,
      };
    }

    mapProgram(programData) {
      if (!programData) return null;
      return {
        id: programData.id,
        program_name: programData.program_name,
        tuition: programData.tuition,
      };
    }
  
    mapAccount(accountData) {
      return {
        id: accountData.id,
        accountType: accountData.account_type,
        email: accountData.email,
        firstName: accountData.first_name,
        lastName: accountData.last_name,
        phone: accountData.phone,
      };
    }

    mapLoan(loanData) {
      return {
        id: loanData.id,
        apr: loanData.apr,
        interestRate: loanData.interest_rate,
        loanTerm: loanData.loan_term,
        tuitionFinanced: loanData.tuition_financed,
        borrowingAmount: loanData.borrowing_amount,
        requiredDownPayment: loanData.required_down_payment,
        firstPaymentDueDate: loanData.first_payment_due_date,
        monthlyPayment: loanData.monthly_payment,
      };
    }
  }
  
  export default LoanApplicationData;