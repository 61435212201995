import React from 'react';
import SupportTag from '../SupportTag';
import FortifyIcon from '../FortifyIcon';

const Cancelled = () => {
  return (
    <>
      <FortifyIcon />

      <div className='p-2 sm:p-4 space-y-4' style={{ textAlign: 'center' }}>
        <div className="text-2xl font-lora"><b>Your Fortify loan was cancelled</b></div>
        <p>At your request, we cancelled your Fortify loan. You won't need to make any more payments.</p>
        <p>
          Thank you for considering Fortify. If you change your mind, we'd love to have you apply again.
        </p>
        <SupportTag />
      </div>
    </>
  );
};

export default Cancelled;