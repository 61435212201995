import React from 'react';
import SupportTag from '../SupportTag';
import FortifyIcon from '../FortifyIcon';

const FailedIdentityVerification = ({applicationData}) => {
  const ofacRejected = applicationData?.student?.ofacStatus === "rejected";
  const nameMismatch = !applicationData?.student?.nameVerifiedAgainstStripe;
  const dobMismatch = !applicationData?.student?.dobVerifiedAgainstStripe;
  const ssnMismatch = !applicationData?.student?.ssnVerifiedAgainstStripe;

  const mismatches = [];

  if (nameMismatch) mismatches.push("legal name");
  if (dobMismatch) mismatches.push("date of birth");
  if (ssnMismatch) mismatches.push("SSN");

  const getTitleAndDescription = () => {
    let title = "";
    let description = "";

    if (ofacRejected) {
      title = "OFAC Flag";
      description = "Your application was flagged by the U.S. Office of Foreign Assets Control. Please contact Fortify for further instructions.";
    } else {
      title = "Further identity verification required";

      if (mismatches.length === 1) {
        description = `We were unable to automatically confirm your ${mismatches[0]}.`
      } else if (mismatches.length === 2) {
        description = `We were unable to automatically confirm your ${mismatches[0]} and ${mismatches[1]}.`
      } else if (mismatches.length == 3) {
        description = `We were unable to automatically confirm your ${mismatches[0]}, ${mismatches[1]}, and ${mismatches[2]}.`
      } else {
        description = "We were unable to automatically confirm your identity."
      }
    }
    return { title, description };
  };

  const { title, description } = getTitleAndDescription();

  return (
    <>
      <FortifyIcon />

      <div className='p-2 sm:p-4 space-y-4' style={{ textAlign: 'center' }}>
        <div className="text-2xl font-lora"><b>{title}</b></div>
        <p>{description}</p>
        {!ofacRejected && (
          <p>
            Please email us at <a href="mailto:help@fortifyedu.com" className="underline">help@fortifyedu.com</a> or call (214) 644-6444, and we'll help you proceed.
          </p>
        )}
      </div>
    </>
  );
};

export default FailedIdentityVerification;