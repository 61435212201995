import React from 'react';
import SupportTag from '../SupportTag';
import FortifyIcon from '../FortifyIcon';

const Archived = () => {
  return (
    <>
      <FortifyIcon />

      <div className='text-center'>
        <p className='font-lora text-2xl pb-6'><b>Your application was archived.</b></p>
        <p className='mb-4'>If this is a mistake, please reach out to Fortify and we'll help you continue.</p>
        <SupportTag />
      </div>
    </>
  )
};

export default Archived;


