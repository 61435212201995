import React from 'react';
import FortifyIcon from '../FortifyIcon';

const Rejection = () => {
  return (
    <>
      <FortifyIcon />

      <div className='text-center'>
        <p className='font-lora text-2xl pb-6'><b>Thank you for applying</b></p>
        <p className='mb-4'>You'll be notified of your application decision by email.</p>
      </div>
    </>
  )
};

export default Rejection;


