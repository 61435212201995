import React, { useState, useEffect } from 'react';
import { Button, TextField, Autocomplete } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchSchoolsAndPrograms } from '../api/schools';
import { fake_schools } from '../constants';
import LoadingComponent from './LoadingComponent';
import DisclaimerModal from './DisclaimerModal';

import { addMonths, addDays } from 'date-fns';
import FortifyIcon from './FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../utils/errorLogging';

const ProgramSelector = () => {
  const [schools, setSchools] = useState([]);
  const [programs, setPrograms] = useState({});
  const [source, setSource] = useState('generic');

  const [preSelectedSchoolName, setPreSelectedSchoolName] = useState('');
  const [preSelectedSchoolLogo, setPreSelectedSchoolLogo] = useState(null);

  const [schoolId, setSchoolId] = useState('');
  const [programId, setProgramId] = useState('');
  const [selectedProgram, setSelectedProgram] = useState(null);

  const [showProgramDropdown, setShowProgramDropdown] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isSchoolDropdownDisabled, setIsSchoolDropdownDisabled] = useState(false);

  const [isDisclaimerModalOpen, setIsDisclaimerModalOpen] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const { fetchedSchools, fetchedPrograms } = await fetchSchoolsAndPrograms();

        const realSchoolNames = fetchedSchools.map(school => school.name);
        const uniqueFakeSchools = fake_schools.filter(fake => !realSchoolNames.includes(fake));
        const combinedSchools = [...fetchedSchools, ...uniqueFakeSchools.map(name => ({ id: name, name }))];
        combinedSchools.sort((a, b) => a.name.localeCompare(b.name));

        setSchools(combinedSchools);
        setPrograms(fetchedPrograms);

        const urlSchoolId = searchParams.get('school');
        if (urlSchoolId) {
          setSource('direct');
          const foundSchool = combinedSchools.find(school => school.id === urlSchoolId);

          if (foundSchool) {
            if (foundSchool.logo) {
              setPreSelectedSchoolLogo(foundSchool.logo)
            }
            setSchoolId(foundSchool.id);
            setShowProgramDropdown(true);
            setIsSchoolDropdownDisabled(true);
            setPreSelectedSchoolName(foundSchool.name);
          } else {
            navigate('/start');
          }
        }
      } catch (error) {
        logErrorToConsole("Failed to fetch schools and programs", error);
        logErrorToSentryWithContext(error);
      }
    };

    fetchData();
  }, [searchParams, navigate]);

  const handleSchoolChange = (event, value) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    if (value && !uuidRegex.test(value.id)) {
      navigate(`/coming-soon?school=${encodeURIComponent(value.name)}`);
    } else if (value) {
      setSchoolId(value.id);
      setShowProgramDropdown(true);
    } else {
      setSchoolId('');
      setShowProgramDropdown(false);
    }
    setProgramId('');
    setStartDate('');
    setStartDateError('');
  };

  const handleProgramChange = (event, value) => {
    if (value) {
      setProgramId(value.id);
      setSelectedProgram(value);
    } else {
      setProgramId('');
      setSelectedProgram(null);
    }
  };

  const handleNextClick = () => {
    setIsDisclaimerModalOpen(true);
  };

  const handleProceedAfterDisclaimer = () => {
    setIsDisclaimerModalOpen(false);
    navigate(`/signup?school=${schoolId}&program=${programId}&start_date=${startDate}&source=${source}`);
  };

  const handleDeclineDisclaimer = () => {
    setIsDisclaimerModalOpen(false);
    window.location.reload();
  };

  const fortyFiveDaysFromNow = new Date();
  fortyFiveDaysFromNow.setDate(fortyFiveDaysFromNow.getDate() + 46);

  const validateStartDate = (value) => {
    const selectedProgram = programs[schoolId]?.find(program => program.id === programId);
    const defaultLengthInMonths = selectedProgram?.default_length;
    const shouldValidateGradDate = (defaultLengthInMonths > 2 && !selectedProgram?.allow_applications_close_to_or_after_graduation)

    const today = new Date();
    const startDate = new Date(value + 'T00:00:00');
    const gradDate = addMonths(startDate, defaultLengthInMonths);

    const maximumStartDateDelayInDays = 90;
    const minimumDistanceToGradDateInDays = 60;

    const earliestAllowedStartDate = addMonths(addMonths(today, -defaultLengthInMonths), -2);
    const latestAllowedStartDate = addDays(today, maximumStartDateDelayInDays);

    const earliestAllowedGradDate = addDays(today, minimumDistanceToGradDateInDays);
    const latestAllowedGradDate = addMonths(addMonths(latestAllowedStartDate, defaultLengthInMonths), 2);

    const yearOfStartDate = startDate.getFullYear();

    if (yearOfStartDate < 2000) {
      setStartDateError('');
      return;
    }

    if (startDate < earliestAllowedStartDate) {
      setStartDateError(`Your start date is over ${defaultLengthInMonths + 2} months ago. Please contact Fortify support and we can help you proceed.`)
    } else if (startDate > latestAllowedStartDate) {
      setStartDateError(`Your start date cannot be more than ${maximumStartDateDelayInDays} days in the future. Please re-apply closer to your start date.`)
    } else if (
      gradDate < earliestAllowedGradDate
      && shouldValidateGradDate
    ) {
      setStartDateError(`It seems like you're too close to the end of your program. To get a Fortify loan, please contact support and we can help you proceed.`)
    } else if (
      gradDate > latestAllowedGradDate
    ) {
      setStartDateError(`Your graduation date is too far in the future. Please contact Fortify support and we can help you proceed.`)
    } else {
      setStartDateError('');
    }
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
    validateStartDate(event.target.value);
  };

  const startDateObject = new Date(startDate + 'T00:00:00');
  const startDateYear = startDateObject.getFullYear();

  if (
    searchParams.get('school') && !schoolId
  ) {
    return <LoadingComponent />
  }

  return (
    <div className="application-background">
      <div className="application-container">
        <div className="w-full h-20 flex justify-center">
          {preSelectedSchoolLogo ? (
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
              <img style={{ width: '64px', height: '64px', borderRadius: '50%', position: 'absolute', left: "50%", transform: 'translateX(-48px)', display: 'flex', alignItems: 'center' }} src="/circle_logo.png" alt="Fortify Logo" />
              <div style={{ width: '64px', height: '64px', borderRadius: '50%', border: '2px solid black', position: 'absolute', left: '50%', transform: 'translateX(0px)', backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ width: '64px', height: '64px', borderRadius: '50%' }} src={preSelectedSchoolLogo} alt="School Logo" />
              </div>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <FortifyIcon />
            </div>
          )}
        </div>

        <div className='w-full sm:w-4/5 text-center'>
          {preSelectedSchoolName ? (
            <>
              <h1 className="text-2xl pb-2 font-lora"><b>Apply to Fortify for</b></h1>
              <h1 className="card-title"><b>{preSelectedSchoolName}</b></h1>
            </>
          ) : (
            <h1 className="card-title"><b>Apply to Fortify</b></h1>
          )}
          {preSelectedSchoolName ? (
            <h2 className="pb-8">To get started, tell us about which program at {preSelectedSchoolName} you're attending.</h2>
          ) : (
            <h2 className="pb-8">To get started, tell us about the school & program you're attending.</h2>
          )}
        </div>
        <div className="flex flex-col space-y-5 w-full text-center">
          {!preSelectedSchoolName && (
            <Autocomplete
              id="school-select"
              options={schools}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Select School" />}
              onChange={handleSchoolChange}
              fullWidth
              value={schools.find(school => school.id === schoolId) || null}
              disabled={isSchoolDropdownDisabled}
            />
          )}
          

          {showProgramDropdown && (
            <>
              <Autocomplete
                id="program-select"
                options={programs[schoolId] || []}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Select Program" />}
                onChange={handleProgramChange}
                fullWidth
              />
              <TextField
                id="start-date"
                label="Start Date"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputProps={{ max: fortyFiveDaysFromNow.toISOString().split('T')[0] }}
                value={startDate}
                onChange={handleStartDateChange}
                error={!!startDateError}
                helperText={startDateError}
                disabled={!programId}
              />
            </>
          )}

          <Button 
            variant="contained" 
            disabled={!programId || !startDate || !!startDateError || (startDateYear < 2000)} 
            color="primary"
            className="mt-8 w-full text-white lowercase"
            onClick={handleNextClick}
          >
            Next
          </Button>

          <DisclaimerModal
            open={isDisclaimerModalOpen}
            onClose={() => setIsDisclaimerModalOpen(false)}
            onProceed={handleProceedAfterDisclaimer}
            onDecline={handleDeclineDisclaimer}
            requiresDownPayment={selectedProgram?.down_payment_required}
            schoolId={schoolId}
            programId={programId}
          />

          <div
            className="w-full text-legal-gray text-sm mt-4 text-left space-y-2 md:space-y-1"
          >
            <p>
              Already signed up with Fortify? Log in <a href="/login" className="fortify-green" style={{ fontWeight: 'bold' }}><u>here</u></a>.
            </p>
            <p>
              If you have a question about Fortify, try our <a href="https://help.fortifyedu.com" className="fortify-green" style={{ fontWeight: 'bold' }}><u>help center</u></a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramSelector;
