import React from 'react';
import SupportTag from '../SupportTag';
import FortifyIcon from '../FortifyIcon';

const PendingBankVerification = () => {
  return (
    <>
      <FortifyIcon />

      <div className='p-2 sm:p-4 space-y-4' style={{ textAlign: 'center' }}>
        <div className="text-2xl font-lora"><b>Waiting for your bank to verify you</b></div>
        <p>Before you can proceed, we need to wait 1-2 business days for your bank to verify your identity.</p>
        <p>
          There's nothing you need to do right now. We'll let you know when it's time to finish finalizing your loan!
        </p>
        <SupportTag />
      </div>
    </>
  );
};

export default PendingBankVerification;