import React, { useState } from 'react';
import { Button, TextField, Alert, CircularProgress } from '@mui/material';
import { updateLoanApplication } from '../../api/application';
import FortifyIcon from '../FortifyIcon';
import { minTuitionFinanced } from '../../constants';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const Approval = ({ applicationData, updateStage }) => {
  /* State variables */
  const [tuitionRequest, setTuitionRequest] = useState(null);

  const [localError, setLocalError] = useState('');
  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false); 

  /* Local variables */
  const downPayment = parseFloat(applicationData?.loan?.requiredDownPayment) || 0;
  const minTuitionRequest = downPayment + minTuitionFinanced;
  const programTuition = parseFloat(applicationData?.program?.tuition);

  /* Helper methods */
  const formatTuitionRequest = (value) => {
    if (!value) return '';
    const number = parseInt(value, 10);
    return isNaN(number) ? '' : `$${number.toLocaleString()}`;
  };

  const handleChangeTuitionRequest = (e) => {
    const value = parseInt(e.target.value.replace(/\D/g, ''), 10);

    setLocalError('');
    setServerError('');

    if (isNaN(value)) {
      setTuitionRequest(null);
      return;
    }

    setTuitionRequest(value);

    if (value < minTuitionRequest || value > programTuition) {
      setLocalError(`Please enter an amount between $${minTuitionRequest.toLocaleString()} and $${programTuition.toLocaleString()}.`);
    }
  };

  const handleSubmitTuitionRequest = async () => {
    try {
      setIsLoading(true);
      const response = await updateLoanApplication(applicationData?.id, { tuition_request: tuitionRequest });
      updateStage(response.next_step);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setServerError(error?.response?.data[0] || error?.response?.data?.error || "There was an error setting your tuition request. Please reach out to Fortify, and we'll help you proceed with your loan.");

      logErrorToConsole("Failed to update tuition request", error);

      if (!error.response || !error.response.status || error.response.status !== 400) {
        logErrorToSentryWithContext(error, {
          applicationId: applicationData.id,
          tuitionRequest,
        });
      }
    }
  };

  return (
    <>
      <FortifyIcon />

      <div className='w-full md:w-4/5 lg:w-3/4 text-center'>
        <h1 className="text-2xl sm:text-3xl font-lora"><b>Congratulations, <br/>you're approved!</b></h1>

        <div className="my-4">
          <img className="approval-image" src="/celebration.png" alt="Celebration"></img>
        </div>

        <h2 className="mb-8">Enter the amount you would like to borrow. The maximum you can request is <b>${programTuition.toLocaleString()}</b>. Don't worry - the amount you enter will be confirmed by your school.*</h2>

        <TextField
          type="text" 
          value={formatTuitionRequest(tuitionRequest)} 
          onChange={handleChangeTuitionRequest}
          error={!!localError}
          helperText={localError}
          variant="outlined"
          label="Your tuition request"
          InputProps={{ inputProps: { min: minTuitionRequest, max: programTuition } }}
          fullWidth
        />
        
        {serverError && (
          <div className="mt-4">
            <Alert style={{ maxWidth: '400px', margin: 'auto', textAlign: 'center' }} severity="error">{serverError}</Alert>
          </div>
        )}

        <div className="mt-8">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button 
              variant="contained" 
              color="primary" 
              className='w-full'
              style={{ fontSize: '1rem' }}
              onClick={handleSubmitTuitionRequest}
              disabled={!tuitionRequest || !!localError || !!serverError}
            >
              Next
            </Button>
          )}
        </div>

        <div className='mt-4 sm:mt-8 text-left text-legal-gray' style={{ fontSize: '0.75rem' }}>
          <p>*Before you complete your loan by accepting the final terms & signing your promissory note with Fortify, your loan amount and payback terms may change depending on information your school provides to us.</p>
          <br/>
          <p>To continue, we may require you and your school to provide additional information to verify your identity, enrollment status, and loan amount. Your loan cannot be completed and funded if you or your school do not provide the appropriate information, or if we cannot verify that information to confirm your identity.</p>
        </div>
      </div>
    </>
  );
};

export default Approval;
