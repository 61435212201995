import React from 'react';

const FortifyIcon = () => {
  return (
    <div className="pb-4 text-center">
      <img style={{ width: '60px', height: '60px' }} src="/circle_logo.png" alt="Fortify Logo"></img>
    </div>
  )
};

export default FortifyIcon;